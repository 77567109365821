import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/our-food',
    name: 'ourfood',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/OurFoodView.vue')
  },
  {
    path: '/order',
    name: 'order',
    component: () => import(/* webpackChunkName: "about" */ '../views/OrderView.vue')
  },
  {
    path: '/locations',
    name: 'locations',
    component: () => import(/* webpackChunkName: "about" */ '../views/LocationsView.vue')
  },
  {
    path: '/app-download',
    name: 'appdownload',
    component: () => import(/* webpackChunkName: "about" */ '../views/AppDownloadView.vue')
  },
  {
    path: '/contact',
    name: 'contact',
    component: () => import(/* webpackChunkName: "about" */ '../views/ContactView.vue')
  },
  {
    path: '/tipton',
    name: 'tipton',
    component: () => import(/* webpackChunkName: "about" */ '../views/locations/TiptonView.vue')
  },
  {
    path: '/edgbaston',
    name: 'edgbaston',
    component: () => import(/* webpackChunkName: "about" */ '../views/locations/EdgbastonView.vue')
  },
  {
    path: '/dudley',
    name: 'dudley',
    component: () => import(/* webpackChunkName: "about" */ '../views/locations/DudleyView.vue')
  },
  {
    path: '/stafford-rd',
    name: 'staffordrd',
    component: () => import(/* webpackChunkName: "about" */ '../views/locations/StaffordRoadView.vue')
  },
  {
    path: '/newbridge',
    name: 'newbridge',
    component: () => import(/* webpackChunkName: "about" */ '../views/locations/NewbridgeView.vue')
  },
  {
    path: '/evesham',
    name: 'evesham',
    component: () => import(/* webpackChunkName: "about" */ '../views/locations/EveshamView.vue')
  },
  {
    path: '/returns-policy',
    name: 'returnspolicy',
    component: () => import(/* webpackChunkName: "about" */ '../views/ReturnsPolicy.vue')
  },
  {
    path: '/competition',
    name: 'competition',
    component: () => import(/* webpackChunkName: "about" */ '../views/CarCompetitionView.vue')
  },
  {
    path: '/competition/terms',
    name: 'competitionterms',
    component: () => import(/* webpackChunkName: "about" */ '../views/CarCompetitionTermsView.vue')
  }
]

const router = createRouter({
  history: createWebHistory(),
  routes
})

router.beforeEach(() => {
  window.scrollTo(0, 0)
})

export default router
